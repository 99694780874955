import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { variables } from '../../Variables';

function Areas() {
    const navigate = useNavigate();
    const [nombreArea, setNombreArea] = useState('');
    const [unidadSeleccionada, setUnidadSeleccionada] = useState('');
    const [unidadesNegocio, setUnidadesNegocio] = useState([]);
    const [selectedUnidad, setSelectedUnidad] = useState('');
    const [selectedUnidadId, setSelectedUnidadId] = useState(null);
    const [unidadesConAreas, setUnidadesConAreas] = useState([]);
    const [botonDeshabilitado4, setBotonDeshabilitado4] = useState(true);
    const [editingAreaId, setEditingAreaId] = useState(null);

    const handleEdit = async (areaId, nombreArea) => {
        try {
            const response = await axios.patch(variables.API_URL + `Proyecto/UpdateArea/${areaId}`, {
                areaId,
                nombreArea
            });
            console.log(response.data);
            //window.location.reload();
        } catch (error) {
            console.error('Error during area update:', error);
        }
    };


    const handleEditButtonClick = (event, areaId) => {
        event.preventDefault();
        setEditingAreaId(areaId);
    };

    const agregarNuevaArea = async () => {
        try {
            if (nombreArea.trim() === '' || !selectedUnidad) {
                console.log('El campo de entrada está vacío o no se ha seleccionado una unidad. No se agregará el área.');
                return;
            }
            
            const nuevaAreaResponse = await axios.post(variables.API_URL + 'Proyecto/AddArea', {
                nombreArea: nombreArea,
                unidadId: selectedUnidadId,
            });
    
            console.log('Respuesta:', nuevaAreaResponse.data.areaId);
    
            setNombreArea('');
            setBotonDeshabilitado4(true);
            toast.success("Area añadida");
        } catch (error) {
            console.error('Error al agregar área', error);
        }
    };
    

    const handleInputChangeArea = (event) => {
        const nuevoValor = event.target.value;
        setNombreArea(nuevoValor);

        setBotonDeshabilitado4(!nuevoValor.trim());
    };

    const handleSelectChange = (event) => {
        setUnidadSeleccionada(event.target.value);
        setBotonDeshabilitado4(nombreArea.trim() === '' || event.target.value === '');
    };

    const handleVolver = () => {
        navigate('/Config');
    };

    const obtenerAreas = async () => {
        try {
        const response = await axios.get(variables.API_URL + 'Proyecto/unidades-negocio');
        setUnidadesNegocio(response.data); 
        const unidadesConAreas = response.data.filter((unidad) => unidad.areas.length > 0);
        setUnidadesConAreas(unidadesConAreas); 
        //unidadesConAreas.map(r => console.log(r.areas))
        console.log(response.data)
        response.data.forEach(unidad => {
            console.log(`Unidad de negocio: ${unidad.nombreUnidad}`);
            unidad.areas.forEach(area => {
                console.log(`Área: ${area}`);
            });
        });
        } catch (error) {
        console.error('Error al obtener unidades', error);
        }
    };
    const handleUnidadChange = (event) => {
        const selectedUnidadId = event.target.value;
    
        const selectedUnidad = unidadesNegocio.find(unidad => unidad.unidadId === parseInt(selectedUnidadId));
    
        if (selectedUnidad) {
            setSelectedUnidad(selectedUnidad);
            setSelectedUnidadId(selectedUnidadId);
            console.log(`Unidad seleccionada: ${JSON.stringify(selectedUnidad)}`);
            console.log(`unidad Id seleccionada: ${selectedUnidadId}`)
        } else {
            console.log(`No se encontró una unidad con el ID: ${selectedUnidadId}`);
        }
    };

    useEffect(() => {
        obtenerAreas();
    }, []);
    
    const handleKeyDown = (event, areaId, nuevoValor) => {
        if (event.key === 'Enter') {
            handleEdit(areaId, nuevoValor);
            setEditingAreaId(null);
        }
    };

    return (
        <form action="">
        <button onClick={handleVolver} className="btn btn-success m-2">
            Volver
        </button>
        <div className="contenedor form-group">
            <label>Unidad</label>
            <select
            style={{ width: '300px', margin: '10px', padding: '5px' }}
            className="form-control"
            value={selectedUnidad ? selectedUnidad.unidadId : ''}
            
            onChange={handleUnidadChange}
            >
            <option value="">Selecciona una unidad</option>
            {unidadesNegocio.map((unidad) => (
                <option key={unidad.unidadId} value={unidad.unidadId}>
                {unidad.nombreUnidad}
                </option>
            ))}
            </select>
            <label>Area</label>
            <input
            style={{ width: '300px', margin: '10px', padding: '5px' }}
            type="text"
            className="form-control"
            id="unidad"
            value={nombreArea}
            onChange={handleInputChangeArea}
            />
            <button className="btn btn-success" style={{ width: '100px' }} onClick={agregarNuevaArea} disabled={botonDeshabilitado4}>
            Añadir
            </button>
        </div>
        <div className="m-3">
            <table className="tabla-clientes table mt-3 table-bordered">
            <thead>
                <tr>
                    <th>Unidad</th>
                    <th>Área</th>
                </tr>
            </thead>
            <tbody>
                {Array.isArray(unidadesConAreas) &&
                unidadesConAreas.map((unidad) => (
                    unidad.areas.map((area, index) => (
                        <tr key={`${unidad.unidadId}-${index}`}>
                            {index === 0 ? (
                                <td rowSpan={unidad.areas.length}>{unidad.nombreUnidad}</td>
                            ) : null}
                            <td>
                                {editingAreaId === area.areaId ? (
                                    <input
                                        type="text"
                                        defaultValue={area.nombreArea}
                                        onBlur={() => setEditingAreaId(null)}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                handleEdit(area.areaId, e.target.value);
                                                setEditingAreaId(null);
                                            }
                                        }}
                                    />
                                ) : (
                                    area.nombreArea
                                )}
                            </td>
                        </tr>
                    ))
                ))}
            </tbody>
            </table>
        </div>
        </form>
    );
}

export default Areas;
