import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "bootstrap/dist/css/bootstrap.min.css"
import '../../App.css';
import moment from 'moment';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { variables } from '../../Variables';

function FormComponent(){
    const location = useLocation();
    const navigate = useNavigate();
    const { id: urlId } = useParams();
    const {isEditing: isEditing} = useParams();
    const [id, setId] = useState("");
    const [codigo, setCodigo] = useState([]);
    const [selectedTipo, setSelectedCodigo] = useState('');
    const [codigoGenerado, setCodigoGenerado] = useState('');
    const [consecutivo, setConsecutivo] = useState(0);
    const [lastConsecutivo, setLastConsecutivo] = useState(null);
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [nomProyecto, setNomProyecto] = useState("");
    const [prioridad, setPrioridad] = useState("");
    const [estado, setEstado] = useState([]);
    const [selectedEstado, setSelectedEstado] = useState('');
    const [dia_inicio, setDiaInicio] = useState(Date);
    const [dia_fin, setDiaFin] = useState(Date);
    const [liderProyecto, setLiderProyecto] = useState("");
    const [comentarios, setComentarios] = useState("");
    const [proyectos, setProyectos] = useState([]);
    const [cliente, setCliente] = useState([]);
    const [selectedCliente, setSelectedCliente] = useState('');
    const [selectedClienteId, setSelectedClienteId] = useState(null);
    const [etapa, setEtapa] = useState([]);
    const [selectedEtapa, setSelectedEtapa] = useState('');
    const [unidadesNegocio, setUnidadesNegocio] = useState([]);
    const [selectedUnidad, setSelectedUnidad] = useState('');
    const [selectedUnidadId, setSelectedUnidadId] = useState(null);
    const [selectedArea, setSelectedArea] = useState('');
    const [selectedAreaId, setSelectedAreaId] = useState(null); 
    const [newId, setNewId] = useState(null);
    const [areaSeleccionada, setAreaSeleccionada] = useState('')
    const proyecto = proyectos.find((proyecto) => proyecto.id === parseInt(urlId));
    
    useEffect(() => {
        const obtenerClientes = async () => {
            try {
                const response = await axios.get(variables.API_URL + 'Proyecto/activos');
                setCliente(response.data);
                console.log(response.data)
            } catch (error) {
                console.error('Error al obtener clientes:', error);
            }
        };
        
        obtenerClientes();
    }, []);

    const handleClienteChange = (event) => {
        const selectedClienteId = event.target.value;
    
        const selectedCliente = cliente.find(c => c.clienteId === parseInt(selectedClienteId));
    
        if (selectedCliente) {
            setSelectedCliente(selectedCliente);
            setSelectedClienteId(selectedClienteId);
            generarCodigo(selectedClienteId, newId);
            console.log(`Cliente seleccionado: ${JSON.stringify(selectedCliente)}`);
        } else {
            console.log(`No se encontró un cliente con el ID: ${selectedClienteId}`);
        }
    };

    useEffect(() => {
        const obtenerEtapas = async () => {
            try {
                const response = await axios.get(variables.API_URL +'Proyecto/GetEtapa');
                setEtapa(response.data);
                console.log(response.data)
            } catch (error) {
                console.error('Error al obtener etapas:', error);
            }
        };

        obtenerEtapas();
    }, []);

    const handleEtapaChange = (event) => {
        const selectedEtapaId = event.target.value;
    
        const selectedEtapa = etapa.find(c => c.etapasId === parseInt(selectedEtapaId));
        
        if (selectedEtapa) {
            setSelectedEtapa(selectedEtapa);
            
            console.log(`etapa seleccionada: ${JSON.stringify(selectedEtapa)}`);
            
        } else {
            console.log(`No se encontró una etapa con el ID: ${selectedEtapaId}`);
        }
    };

    useEffect(() => {
        const obtenerEstados = async () => {
            try {
                const response = await axios.get( variables.API_URL + 'Proyecto/GetEstados');
                setEstado(response.data);
                console.log(response.data)
            } catch (error) {
                console.error('Error al obtener estados:', error);
            }
        };

        obtenerEstados();
    }, []);

    const handleEstadoChange = (event) => {
        const selectedEstadoId = event.target.value;
    
        
        const selectedEstado = estado.find(c => c.estadoId === parseInt(selectedEstadoId));
    
        if (selectedEstado) {
            setSelectedEstado(selectedEstado);
            console.log(`estado seleccionada: ${JSON.stringify(selectedEstado)}`);
        } else {
            console.log(`No se encontró un estado con el ID: ${selectedEstadoId}`);
        }
    };

    useEffect(() => {
        const obtenerTipos = async () => {
            try {
                const response = await axios.get(variables.API_URL + 'Proyecto/GetType');
                setCodigo(response.data);
                console.log(response.data)
            } catch (error) {
                console.error('Error al obtener tipos:', error);
            }
        };
        const obtenerProyectos = async () => {
            const result = await axios.get(
                variables.API_URL + "Proyecto/GetProyecto"
            );
            setProyectos(result.data);
            console.log(result.data);
        }

        obtenerTipos();
        obtenerProyectos();
    }, []);

    const handleTypeChange = (event) => {
        const selectedTipoId = event.target.value;
        const selectedTipo = codigo.find(t => t.typeId === parseInt(selectedTipoId));
    
        if (selectedTipo) {
            setSelectedCodigo(selectedTipo);
            generarCodigo(selectedClienteId, newId, selectedTipoId); 
            console.log(`tipo seleccionado: ${JSON.stringify(selectedTipo)}`);
        } else {
            console.log(`No se encontró un tipo con el ID: ${selectedTipoId}`);
        }
    };
    

    const generarCodigo = (selectedClienteId, newId, selectedTipoId) => {
        console.log(proyectos)
        if (proyectos && Array.isArray(proyectos)) {
            console.log("Proyectos existentes:", proyectos);
            console.log(newId)
            let codigoGeneradoNuevo = "-";
            let clienteProyectos = "";
            if (selectedTipoId) {
                const selectedTipo = codigo.find(t => t.typeId === parseInt(selectedTipoId));
                const primeraLetra = selectedTipo && selectedTipo.nombre ? selectedTipo.nombre.charAt(0).toUpperCase() : '';
                const codigoRestante = codigoGenerado.split('.').slice(1).join('.');
                codigoGeneradoNuevo = `${primeraLetra}.${codigoRestante}`;
            } else {
                const selectedCliente = cliente?.find(c => c.clienteId === parseInt(selectedClienteId));
                if(selectedCliente){
                    clienteProyectos = proyectos.filter(proyecto => proyecto.cliente === selectedCliente.nombre);
                }
                const primeraLetra = selectedTipoId ? '' : selectedTipo && selectedTipo.nombre ? selectedTipo.nombre.charAt(0).toUpperCase() : '';
                const year = new Date().getFullYear().toString().slice(-2);
                const clienteIdFormatted = String(selectedClienteId).padStart(2, '0');
                const nextConsecutivo = String(clienteProyectos.length + 1).padStart(2, '0');
                const areaId = newId ? String(newId).padStart(2, '0') : '-';
                const unidadId = selectedUnidadId || codigoGeneradoNuevo.split('.')[4] || '-';
                codigoGeneradoNuevo = `${primeraLetra}.${year}${clienteIdFormatted}${nextConsecutivo}.${unidadId}${areaId}`;
            }
            setCodigoGenerado(codigoGeneradoNuevo || "-");
            console.log(`Código generado: ${codigoGeneradoNuevo}`);
        } else {
            console.error("Error: La variable 'proyectos' no está definida o no es un array.");
        }
    };
    
    
    
    
    const handleUnidadChange = (event) => {
        const selectedUnidadId = event.target.value;
    
        const selectedUnidad = unidadesNegocio.find(unidad => unidad.unidadId === parseInt(selectedUnidadId));
    
        if (selectedUnidad) {
            setSelectedUnidad(selectedUnidad);
            setSelectedUnidadId(selectedUnidadId);
            setSelectedArea('');
            generarCodigo(selectedClienteId, newId, selectedUnidadId);
            console.log(`Unidad seleccionada: ${JSON.stringify(selectedUnidad)}`);
            console.log(`unidad Id seleccionada: ${selectedUnidadId}`)
            
        } else {
            console.log(`No se encontró una unidad con el ID: ${selectedUnidadId}`);
        }
    };

    const handleAreaChange = (event) => {
        const selectedAreaId = event.target.value;
        console.log(selectedAreaId)
        const areasDeUnidadSeleccionada = selectedUnidad.areas;
        const areaSeleccionada = areasDeUnidadSeleccionada.find(area => area.areaId === parseInt(selectedAreaId));
        setAreaSeleccionada(areaSeleccionada)
        console.log(areaSeleccionada)
        setSelectedArea(selectedAreaId);
        setSelectedAreaId(selectedAreaId); 
        console.log(`Área seleccionada: ${selectedAreaId}`);
        const mappingForSelectedUnidad = Object.keys(selectedUnidad.areas).map(key => Number(key) + 1);
        console.log(mappingForSelectedUnidad)
        const areaIdSeleccionada = areaSeleccionada.areaId;
        const areaSeleccionadaEnUnidad = selectedUnidad.areas.find(r => r.areaId === parseInt(areaIdSeleccionada));

        if (areaSeleccionadaEnUnidad) {
            const indiceDeAreaSeleccionada = selectedUnidad.areas.findIndex(r => r.areaId === parseInt(areaIdSeleccionada));
            if (indiceDeAreaSeleccionada !== -1) {
                const newId = mappingForSelectedUnidad[indiceDeAreaSeleccionada];
                setNewId(newId)
                console.log(newId)
                generarCodigo(selectedClienteId, newId);
            } else {
                console.log('El área seleccionada no coincide con ningún área en selectedUnidad.');
            }
        } else {
            console.log('El área seleccionada no coincide con ningún área en selectedUnidad.');
        }
        
    };

    
    useEffect(() => { 
        const Load = async () => {
            const result = await axios.get(
                variables.API_URL + "Proyecto/GetProyecto"
            );
            setProyectos(result.data);
            console.log(result.data);
            generarCodigo(selectedClienteId, newId);
        }

        const editProyecto = () => {
            setCodigoGenerado(proyecto.codigo);
            const tipoString = Array.isArray(proyecto.type) ? proyecto.type[0] : proyecto.type;
            const selectedTipo = Array.isArray(proyecto.type) ? proyecto.type[0] : codigo.find(t => t.nombre === tipoString);
            setSelectedCodigo(selectedTipo);
            setNomProyecto(proyecto.nombreProyecto);
            const clienteString = Array.isArray(proyecto.cliente) ? proyecto.cliente[0] : proyecto.cliente;
            const selectedCliente = Array.isArray(proyecto.cliente) ? proyecto.cliente[0] : cliente.find(c => c.nombre === clienteString);
            setSelectedCliente(selectedCliente);
            console.log('Cliente después de set:', clienteString)
            setPrioridad(proyecto.prioridad);
            const estadoString = Array.isArray(proyecto.estado) ? proyecto.estado[0] : proyecto.estado;
            const selectedEstado = Array.isArray(proyecto.estado) ? proyecto.estado[0] : estado.find(est => est.nombreEstado === estadoString);
            setSelectedEstado(selectedEstado);
            setDiaInicio(moment(proyecto.diaInicio).format('YYYY-MM-DD'));
            setDiaFin(moment(proyecto.diaFin).format('YYYY-MM-DD'));
            setLiderProyecto(proyecto.liderProyecto);
            const etapaString = Array.isArray(proyecto.etapa) ? proyecto.etapa[0] : proyecto.etapa;
            const selectedEtapa = Array.isArray(proyecto.etapa) ? proyecto.etapa[0] : etapa.find(e => e.nombreEtapa === etapaString);
            setSelectedEtapa(selectedEtapa);
            const unidadString = Array.isArray(proyecto.categorias) ? proyecto.categorias[0] : proyecto.categorias;
            const selectedUnidad = Array.isArray(proyecto.categorias) ? proyecto.categorias[0] : unidadesNegocio.find(e => e.nombreUnidad === unidadString);
            setSelectedUnidad(selectedUnidad)
            if (selectedUnidad && selectedUnidad.areas.length > 0) {
                const selectedArea = selectedUnidad.areas[0];
                setSelectedArea(selectedArea);
            }
            setComentarios(proyecto.comentarios);
        }
        if (proyecto) {
            editProyecto();
        }else{
            if (urlId !== undefined) Load();
        }
        
        // console.log('ID en la URL:', urlId);
        // console.log('Proyectos:', proyectos);
        // console.log('IDs de Proyectos:', proyectos.map(proyecto => proyecto.id));

    }, [proyectos, cliente, codigo, estado, etapa, unidadesNegocio, urlId]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get( variables.API_URL + 'Proyecto/unidades-negocio');
                setUnidadesNegocio(response.data);
            } catch (error) {
                console.error('Error al obtener unidades de negocio:', error);
            }
        };

        fetchData();
    }, []);

    
    async function Save(event) {
        event.preventDefault();
            await axios.post(variables.API_URL + "Proyecto/AddProyecto", {
                codigo: codigoGenerado,
                type: selectedTipo.nombre,
                nombreProyecto: nomProyecto,
                cliente: selectedCliente.nombre,
                prioridad: "Media",
                estado: "Iniciado",
                diaInicio: moment(dia_inicio).format('YYYY-MM-DD'),
                diaFin: moment(dia_fin).add(1, 'days').format('YYYY-MM-DD'),
                etapa: "Cotizado",
                liderProyecto: liderProyecto,
                categorias: selectedUnidad.nombreUnidad,
                comentarios: "-",
            }, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                //withCredentials: true,
            }).then(function (rta) {
                toast.success("Proyecto agregado correctamente"); 
                setTimeout(()=>{
                    setCodigo("");
                    setNomProyecto("");
                    setCliente("");
                    setPrioridad("");
                    setEstado("");
                    setDiaInicio("");
                    setDiaFin("");
                    setEtapa("");
                    setLiderProyecto("");
                    setUnidadesNegocio("");
                    setComentarios("");
                }, 3000)          
            
        }).catch(function (error) {
            alert(error.response.data);
        });
            setTimeout(()=>{

                navigate('/proyectoCrud');
            }, 3000)

    }
    

    async function UpdateProyecto(event) {
        try {
            event.preventDefault();
    
            const proyecto = proyectos.find((u) => u.id === parseInt(urlId));
    
            if (proyecto) {
                await axios.patch(
                    variables.API_URL + `Proyecto/UpdateProyecto/${proyecto.id}`,
                    {
                        id: proyecto.id,
                        codigo: codigoGenerado,
                        type: selectedTipo.nombre,
                        nombreProyecto: nomProyecto,
                        cliente: selectedCliente.nombre,
                        prioridad: prioridad,
                        estado: selectedEstado.nombreEstado,
                        diaInicio: moment(dia_inicio).format('YYYY-MM-DD'),
                        diaFin: moment(dia_fin).format('YYYY-MM-DD'),
                        etapa: selectedEtapa.nombreEtapa,
                        liderProyecto: liderProyecto,
                        categorias: selectedUnidad.nombreUnidad,
                        comentarios: comentarios,
                    }
                );
                
                toast.success("Proyecto actualizado correctamente");
                
                setTimeout(() => {
                    
                    navigate('/proyectoCrud');
                    setId("");
                    setCodigo("");
                    setNomProyecto("");
                    setCliente("");
                    setPrioridad("");
                    setEstado("");
                    setDiaInicio("");
                    setDiaFin("");
                    setEtapa("");
                    setLiderProyecto("");
                    setUnidadesNegocio("");
                    setComentarios("");
                }, 3000);
            } else {
                toast.error("El proyecto no se encontró para la actualización");
            }
        } catch (err) {
            toast.error("Error al actualizar el proyecto: " + err.message);
        }
    }
    
    
    const handleVolver = () => {
        navigate('/proyectoCrud');
    };

    console.log("Estado value: ",selectedUnidad, urlId)
    return (
        
            <form style={{margin:'30px'}}>
                <button onClick={handleVolver} className='btn btn-success m-2'>
                    Volver
                </button>
                <div className="row">
                    <div class=" col-md-4">
                        <label className='p-2'>Código</label>
                        <select onChange={handleTypeChange} type="text" class="form-control"  value={selectedTipo ? selectedTipo.typeId : ''}>
                            <option value="">Selecciona un tipo</option>
                            {Array.isArray(codigo) && codigo.map(c => (
                            <option key={c.typeId} value={c.typeId}>
                                {c.nombre}
                            </option>
                            ))}
                        </select>
                        <input
                        style={{marginTop:'10px'}}
                        type="text"
                        class="form-control"
                        id="codigo"
                        value={selectedCliente ? codigoGenerado : " "}
                        readOnly
                        />
                    </div>
                    <div class="col-md-4 ">
                        <label className='p-2'>Nombre Proyecto</label>
                        <input
                        
                        type="text"
                        class="form-control"
                        id="nombreProyecto"
                        value={nomProyecto}
                        onChange={(event) => {
                            setNomProyecto(event.target.value);
                        }}
                        
                        
                        />
                    </div>
                    <div class="col-md-4">
                        <label className='p-2'>Cliente</label>
                        
                        <select onChange={handleClienteChange } type="text" class="form-control"  value={selectedCliente ? selectedCliente.clienteId : ""} disabled ={urlId !== undefined}
                        >
                            
                            <option value="">Selecciona un cliente</option>
                            {Array.isArray(cliente) && cliente.map(cliente => (
                            <option key={cliente.clienteId} value={cliente.clienteId} >
                                {cliente.nombre}
                            </option>
                            ))}
                        </select>
                    </div>
                    {/* <div class="col-md-4 ">
                        <label className='p-2'>Prioridad</label>
                        <select type="text" class="form-control" id="prioridad"  value={prioridad} onChange={(event) => {setPrioridad(event.target.value);}} >
                            <option value="">Selecciona...</option>
                            <option value="Media" >Media</option>
                            <option value="Alta">Alta</option>
                            <option value="Baja">Baja</option>
                        </select>
                    </div>
                    
                    
                    <div class="col-md-4 ">
                        <label className='p-2'>Dia Inicio</label>
                        <input
                        
                        type="date"
                        class="form-control"
                        id="diaInicio"
                        value={dia_inicio}
                        onChange={(event) => {
                            setDiaInicio(event.target.value);
                        }}
                        
                        />
                    </div>
                    <div class="col-md-4 ">
                        <label className='p-2'>Dia Fin</label>
                        <input
                        
                        type="date"
                        class="form-control"
                        id="diaFin"
                        value={dia_fin}
                        onChange={(event) => {
                            setDiaFin(event.target.value);
                        }}
                        
                        />
                    </div>
                    <div class="col-md-4 ">
                        <label className='p-2'>Estado</label>
                        <select onChange={handleEstadoChange} type="text" class="form-control" value={selectedEstado ? selectedEstado.estadoId : ""} >
                            <option value="">Selecciona un estado</option>
                            {Array.isArray(estado) && estado.map(e => (
                            <option key={e.estadoId} value={e.estadoId}>
                                {e.nombreEstado}
                            </option>
                            ))}
                        </select>
                    </div>
                    <div class="col-md-4 ">
                        <label className='p-2'>Etapas</label>
                        <select onChange={handleEtapaChange} type="text" class="form-control" value={selectedEtapa ? selectedEtapa.etapasId : ""} >
                            <option value="">Selecciona una etapa</option>
                            {Array.isArray(etapa) && etapa.map(etapa => (
                            <option key={etapa.etapasId} value={etapa.etapasId}>
                                {etapa.nombreEtapa}
                            </option>
                            ))}
                        </select>
                    </div> */}
                    <div className="col-md-4">
                        <label className='p-2' htmlFor="unidad">Unidad de negocio</label>
                        <select
                            
                            id="unidad"
                            name="unidad"
                            type="text"
                            className="form-control"
                            onChange={handleUnidadChange}
                            value={selectedUnidad ? selectedUnidad.unidadId : ''}
                            disabled ={urlId !== undefined}
                        >
                            <option value="">Selecciona una unidad de negocio</option>
                            {Array.isArray(unidadesNegocio) && unidadesNegocio.map(unidad => (
                                <option key={unidad.unidadId} value={unidad.unidadId}>
                                    {unidad.nombreUnidad}
                                </option>
                            ))}
                        </select>

                        {selectedUnidad && (
                        <div>
                            <label className='p-2' htmlFor="area">Área</label>
                            <select
                            id="area"
                            name="area"
                            type="text"
                            className="form-control"
                            onChange={handleAreaChange}
                            value={selectedArea ? selectedArea.areaId : ''}
                            disabled ={urlId !== undefined}
                            >
                            <option value="">Selecciona un área</option>
                            {selectedUnidad.areas && selectedUnidad.areas.map(area => (
                                <option key={area.areaId} value={area.areaId}>
                                {area.nombreArea}
                                </option>
                            ))}
                            </select>
                        </div>
                        )}
                    </div>
                    <div class="col-md-4 ">
                        <label className='p-2'>Lider del proyecto</label>
                        <input
                        
                        type="text"
                        class="form-control"
                        id="liderProyecto"
                        value={liderProyecto}
                        onChange={(event) => {
                            setLiderProyecto(event.target.value);
                        }}
                        
                        />
                    </div>
                    {/* <div class="col-md-4">
                        <label className='p-2'>Comentarios</label>
                        <input
                        
                        type="text"
                        class="form-control"
                        id="comentarios"
                        value={comentarios}
                        onChange={(event) => {
                            setComentarios(event.target.value);
                        }}
                        />
                    </div> */}

                </div>

                <div className='d-flex flex-wrap justify-content-center mt-4'>
                    { urlId !== undefined ?
                        <button className="btn btn-warning col-3 " style={{width:'100px', marginLeft: '10px'}} onClick={UpdateProyecto}>
                            Update
                        </button>
                        :
                        <button className="btn btn-primary  col-3" onClick={Save} style={{width:'100px', marginRight: '10px'}}>
                            Save
                        </button>
                    }
                </div>
            </form>
        
    );
};

export default FormComponent;

