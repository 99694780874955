import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css"
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import { variables } from "../Variables";
import moment from 'moment';
import '../App.css';
import 'react-toastify/dist/ReactToastify.css';

function Quotaions() {
    const navigate = useNavigate();
    const [id, setId] = useState("");
    const [codigo, setCodigo] = useState("");
    const [nomProyecto, setNomProyecto] = useState("");
    const [cliente, setCliente] = useState("");
    const [prioridad, setPrioridad] = useState("");
    const [estado, setEstado] = useState("");
    const [dia_inicio, setDiaInicio] = useState(Date);
    const [dia_fin, setDiaFin] = useState(Date);
    const [liderProyecto, setLiderProyecto] = useState("");
    const [etapa, setEtapa] = useState("");
    const [categorias, setCategorias] = useState("");
    const [comentarios, setComentarios] = useState("");
    const [proyectos, setProyectos] = useState([]);
    const [filtro, setFiltro] = useState('');

    const ODOO_BACK = variables.PROTOCOL + variables.IP_MODULE + variables.PORT +"/get_sale_order";
    
    useEffect(() => {
        (async () => await Load())();
    }, []);

    const project = [{
        areas: [
            {
                areaId: 1,
                nombreArea: "area 1"    
            }
        ],
        nombreUnidad: "unidad prueba",
        unidadId: 1
    },
    {
        areas: [
            {
                areaId: 2, 
                nombreArea: "area 2"
            },
            {
                areaId: 3, 
                nombreArea: "area 3"
            }
        ],
        nombreUnidad: "unidad prueba 2",
        unidadId: 2
    },
    {
        areas: [
            {
                areaId: 4, 
                nombreArea: "area 4"
            },
            {
                areaId: 5, 
                nombreArea: "area 5"
            },
            {
                areaId: 6, 
                nombreArea: "area 6"
            },
            {
                areaId: 7, 
                nombreArea: "area 7"
            }
        ],
        nombreUnidad: "unidad prueba 3",
        unidadId: 3
    }
    ]

    async function Load() {  
        fetch(ODOO_BACK, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            methods: "GET",
        })
            .then(res => res.json())
            .then(data => {setProyectos(data)
                idReset(project)})
            .catch(rejected => { console.log(rejected) })
    }

    function idReset(data){
        const negocioId = data.map(v => v.unidadId)
        const newId = data.map(v => Object.keys(v.areas).map(r => Number(r) + 1))
        console.log(negocioId, newId)
    }


    async function editProyecto(proyectos) {
        setId(proyectos.id);
        setCodigo(proyectos.codigo);
        setNomProyecto(proyectos.nombre_proyecto);
        setEstado(proyectos.estado);
        setDiaInicio(proyectos.dia_inicio);
        setDiaFin(proyectos.dia_fin);
        setEtapa(proyectos.etapas);
        setLiderProyecto(proyectos.liderProyecto);
        setCategorias(proyectos.categorias);
        setComentarios(proyectos.comentarios);

        navigate({
            pathname: `/form/${proyectos.id}`,
            
        });

    }
    
    return (
        <div>

            <button className="btn btn-primary mt-4 botonNew" 
            style={{
                position: 'absolute',
                top: 0,
                right: 0,
                marginRight: '20px', 
                marginTop: '20px',   
            }}

            
            >
                Nuevo Proy
            </button>
            <br />
            <Table responsive striped bordered hover style={{height: '200px' }}>
                <thead>
                <tr>
                    <th>Proyecto</th>
                    <th>Order ref</th>
                    <th>Fecha creación</th>
                    <th>Fecha de orden</th>
                </tr>
                </thead>
                <tbody>
                    {proyectos.map((clave) => (
                    <tr>
                        <td>{clave.name}</td>
                        <td>{clave.client_order_ref}</td>
                        <td>{clave.create_date}</td>
                        <td>{clave.date_order}</td>
                    </tr>
                    ))}
                </tbody>
            </Table>
            
        </div>
    );
}
export default Quotaions;
