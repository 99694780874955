import React, { useState, useEffect, Component } from 'react';
import '../../App.css';
import "bootstrap/dist/css/bootstrap.min.css"
import axios from 'axios';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { variables } from '../../Variables';
import { MdEdit, MdDelete } from 'react-icons/md';

function Cliente(){
    const navigate = useNavigate();
    const [newCliente, setNewCliente] = useState({clienteNombre: "", clienteId: null})
    const { clienteNombre, clienteId } = newCliente
    const [show, setShow] = useState(false);
    const [clientes, setClientes] = useState([]);
    const [selectedClientes, setSelectedClientes] = useState({});
    const [botonDeshabilitado2, setBotonDeshabilitado2] = useState(true);

    const [newId, setNewId] = useState(null);
    const [selectedId, setSelectedId] = useState();
    const [selectedPortfolioId, setSelectedPortfolioId] = useState();

    const [newNombre, setNewNombre] = useState(null);
    const [selectedNombre, setSelectedNombre] = useState();
    
    const showModal = () => {
        setShow(true);
    };

    const hideModal = () => {
        setShow(false);
    };

    const handleNewCliente = (cliente, event) => {
        setNewCliente({
            ...newCliente,
            [cliente]: event
        })
    }
    
    const toggleClienteCheckbox = async (clienteId) => {
        const currentClienteState = selectedClientes[clienteId] || false;
    
        const newSelectedClientes = {
        ...selectedClientes,
        [clienteId]: !currentClienteState,
        };
        setSelectedClientes(newSelectedClientes);
    
        try {
        const estadoCliente = newSelectedClientes[clienteId] ? 'activar' : 'desactivar';
        await axios.put(variables.API_URL + `Proyecto/${clienteId}/${estadoCliente}`);
        } catch (error) {
        console.error('Error al actualizar el estado del cliente', error);
        }
    };
    
    const updatePortfolioId = async (newClientNombre, clienteId, portfolioId, newPortfolioId) => {
        try {
            await axios.put(variables.API_URL + `Proyecto/${newClientNombre}/${clienteId}/${portfolioId}/${newPortfolioId}/actualizar`);
        } catch (error) {
            console.error('Error al actualizar el portfolioId del cliente', error);
        }
    };

    const agregarNuevoCliente = async () => {
        try {
        const response = await axios.post(variables.API_URL + 'Proyecto/AgregarCliente', {
            nombre: clienteNombre,
            portfolioId: clienteId,
            // No incluir 'id' en la solicitud POST
        });
        setNewCliente({"clienteNombre": ""})
        console.log(response.data);
        obtenerClientes();
        toast.success("Cliente añadido")
        } catch (error) {
        console.error('Error al agregar el nuevo cliente', error);
        }
    };
    // const handleInputChangeCliente = (event) => {
    //     const nuevoValor = event.target.value;
    //     setNombre(nuevoValor);
    //     setBotonDeshabilitado2(!nuevoValor.trim());
    // };
    
    useEffect(() => {
        obtenerClientes();
        setBotonDeshabilitado2(!clienteId || !clienteNombre);
    }, [clienteId, clienteNombre]);

    const obtenerClientes = async () => {
        try {
        const response = await axios.get(variables.API_URL + 'Proyecto/GetCliente');
        const initialSelectedClientes = response.data.reduce(
            (acc, cliente) => ({
            ...acc,
            [cliente.clienteId]: cliente.estado 
            
            }),
            {}
        );
        setClientes(response.data);
        if (newCliente.clienteId == null){
            setNewCliente({"clienteId": Math.max(...Object.values(response.data.map(e => e.portfolioId))) + 1})
        }
        setSelectedClientes(initialSelectedClientes);
        } catch (error) {
        console.error('Error al obtener clientes', error);
        }
        
    };

    const handleVolver = () => {
        navigate('/Config');
    };

    return (
        <div className='m-4'>
            <button onClick={handleVolver} className='btn btn-success m-2'>
                    Volver
                </button>
            <div className='contenedor form-group'>
                <label>Cliente</label>
                <input
                    style={{width:'250px', margin:'10px', padding:'5px'}}
                    type="text"
                    class="form-control"
                    value={clienteNombre}
                    onChange={(e) => handleNewCliente("clienteNombre", e.target.value)}
                />
                <label>Id</label>
                <input
                    style={{width:'50px', margin:'10px', padding:'5px'}}
                    type="text"
                    class="form-control"
                    value={clienteId}
                    onChange={(e) => handleNewCliente("clienteId", e.target.value)}
                />
                <button className='btn btn-success' style={{width:'100px'}} onClick={agregarNuevoCliente} disabled={botonDeshabilitado2}>añadir</button>
            </div>
            <div className='m-3'>
                <table className="tabla-clientes table mt-3">
                    <thead>
                        <tr>
                        <th>Nombre del Cliente</th>
                        <th>ID</th>
                        <th>Activar/Desactivar</th>
                        </tr>
                    </thead>
                    <tbody>
                        {clientes.map((cliente, index) => (
                        <tr key={cliente.clienteId || index}>
                            <td  style={{width:"600px"}}>
                                <label>
                                    <input class="form-control" style={{width:'300px', margin:'10px', padding:'5px'}} 
                                        id={"nombre"+cliente.clienteId} defaultValue={cliente.nombre} 
                                        onClick={() => {
                                            setSelectedNombre(cliente.nombre)
                                        }}
                                        onChange={e => setNewNombre(e.target.value)}>
                                    </input>
                                </label>
                                {
                                    (selectedNombre === cliente.nombre) && 
                                        <>
                                            <button onClick={() => {
                                                if (cliente.nombre!== newNombre) { 
                                                    updatePortfolioId(newNombre, cliente.clienteId, cliente.portfolioId, cliente.portfolioId)
                                                    cliente.nombre = newNombre; } setSelectedNombre(null);
                                                }}
                                                className='btn btn-success m-2'
                                                title="Editar">
                                                    <MdEdit/> Guardar
                                            </button>
                                            <button onClick={() => {
                                                document.getElementById("nombre"+cliente.clienteId).value = cliente.nombre; 
                                                setSelectedNombre(null);}}
                                                className='btn btn-danger m-2'>
                                                    <MdDelete/> Restaurar
                                            </button>
                                        </>
                                }
                            </td>
                            <td style={{width:"400px"}}>
                                <label>
                                    <input class="form-control" style={{width:'50px', margin:'10px', padding:'5px'}} 
                                        id={"cliente"+cliente.clienteId} defaultValue={cliente.portfolioId} 
                                        onClick={() => {
                                            if (selectedId && selectedPortfolioId && cliente.clienteId !== selectedId) {
                                                document.getElementById("cliente"+ selectedId).value = selectedPortfolioId;
                                            }
                                            setSelectedId(cliente.clienteId);
                                            setSelectedPortfolioId(cliente.portfolioId);
                                            setNewId(cliente.portfolioId);
                                        }}
                                        onChange={e => setNewId(e.target.value)}>
                                    </input>
                                </label>
                                    {
                                        (selectedId === cliente.clienteId) && 
                                            <>
                                                <button onClick={() => { 
                                                    if (cliente.portfolioId !== newId) { 
                                                        updatePortfolioId(cliente.nombre, cliente.clienteId, cliente.portfolioId, newId); 
                                                        cliente.portfolioId = newId; } setSelectedId(null); 
                                                    }}
                                                    className='btn btn-success m-2'
                                                    title="Editar">
                                                        <MdEdit/> Guardar
                                                </button>
                                                <button onClick={() => {
                                                    document.getElementById("cliente"+cliente.clienteId).value = cliente.portfolioId; 
                                                    setSelectedId(null);}}
                                                    className='btn btn-danger m-2'>
                                                        <MdDelete/> Restaurar
                                                </button>
                                            </>
                                    }
                            </td>
                            <td>
                                <label>
                                    <input
                                    type="checkbox"
                                    checked={selectedClientes[cliente.clienteId] || false}
                                    onChange={() => toggleClienteCheckbox(cliente.clienteId)}
                                    title={selectedClientes[cliente.clienteId] ? "Cliente activo" : "Cliente inactivo"}
                                    />
                                </label>
                            </td>
                            
                        </tr>
                        ))}
                    </tbody>
                </table>

                {/* <ul className="lista-sin-puntos">
                    {clientes.map((cliente, index) => (
                        // Usar el índice como clave si cliente.id es undefined
                        <li key={cliente.clienteId || index}>
                        <label>
                            <input
                            style={{marginRight:'10px'}}
                            type="checkbox"
                            checked={selectedClientes[cliente.clienteId] || false}
                            onChange={() => toggleClienteCheckbox(cliente.clienteId)}
                            />
                            {cliente.nombre}
                        </label>
                        </li>
                    ))}
                </ul> */}
            </div>
        </div>
        
    );
}
export default Cliente;