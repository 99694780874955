import React, { useState } from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { SidebarData } from './SidebarData';
import './Navbar.css';
import { IconContext } from 'react-icons';

function Navbar() {
    const [sidebar, setSidebar] = useState(false);

    const showSidebar = () => setSidebar(!sidebar);

    return (
        <>
        <IconContext.Provider value={{ color: '#fff' }}>
            <div className='navbar' style={{backgroundColor: '#71639e'}} >
            <Link to='#' className='menu-bars'>
                <FaIcons.FaBars onClick={showSidebar} />
            </Link>
            </div>
            <nav className={sidebar ? 'nav-menu active' : 'nav-menu'} style={{zIndex:'1000', backgroundColor: '#71639e'}}>
            <ul className='nav-menu-items'style={{zIndex:'1000', backgroundColor: '#71639e'}} onClick={showSidebar}>
                <li className='navbar-toggle' style={{backgroundColor: '#71639e'}}>
                <Link to='#' className='menu-bars'>
                    <AiIcons.AiOutlineClose />
                </Link>
                </li>
                {SidebarData.map((item, index) => {
                return (
                    <li key={index} className={item.cName}>
                    <Link to={item.path}>
                        {item.icon}
                        <span>{item.title}</span>
                    </Link>
                    </li>
                );
                })}
            </ul>
            </nav>
        </IconContext.Provider>
        </>
    );
}

export default Navbar;
